import React, { useState } from "react";
import {
  Typography,
  Container,
  Paper,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";

const Policy = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div>
      <Container style={{ marginLeft: "50px", maxWidth: `calc(100% - 50px)` }}>
        <Typography
          variant="h1"
          style={{
            fontSize: "48px",
            marginTop: "30px",
            marginBottom: "20px",
            color: "#6D7580",
          }}
        >
          Policies
        </Typography>
        <Paper elevation={0} style={{ padding: "20px" }}>
          <Typography variant="h2">Privacy Policy</Typography>
          <div>
            <h1>Last Updated: 6 Oct 2023</h1>

            <h2>Welcome to TherapInsights</h2>
            <p>
              These Terms of Use ("Terms") govern your use of our mobile
              application and website ("Service"). Please read these Terms
              carefully before using our Service.
            </p>

            <h2>Acceptance of Terms</h2>
            <p>
              By accessing or using our Service, you agree to comply with and be
              bound by these Terms. If you do not agree to these Terms, please
              do not use our Service.
            </p>

            <h2>Changes to Terms</h2>
            <p>
              We reserve the right to modify or replace these Terms at any time.
              Your continued use of the Service after any such change
              constitutes your acceptance of the new Terms.
            </p>

            <h2>Use of Service and Usage Limitation</h2>
            <p>
              TherapInsights provides transcription and summarisation services.
              The services are intended for personal use.
            </p>

            <h2>Intellectual Property</h2>
            <p>
              All content included in our Service, such as text, graphics,
              logos, and software, is the property of TherapInsights or its
              licensors and is protected by UK and international copyright laws.
            </p>

            <h2>Privacy Policy</h2>
            <p>
              This Privacy Policy complies with the UK Data Protection Act of
              2018. Information We Collect When you use TherapInsights, we may
              collect the following types of information: Personal Data: This
              can include your name, email address, and other information that
              identifies you personally. Usage Data: This includes data
              generated by your use of the service, such as access times and app
              interactions. How We Use Information We use the collected data for
              various purposes, including: To provide and maintain the service
              To notify you about changes to our service To gather analysis or
              valuable information to improve our service Data Sharing and
              Disclosure We do not sell, trade, or otherwise transfer your
              Personal Data to third parties. We may disclose your Personal Data
              in the good faith belief that such action is necessary to: Comply
              with a legal obligation Protect against legal liability Data
              Security We use reasonable security measures to protect the
              confidentiality of the Personal Data collected through
              TherapInsights. However, no method of transmission or storage is
              100% secure, and we cannot guarantee its absolute security.
            </p>

            <h2>Expected Procedures and Liability</h2>
            <p>
              Our uptime is subject to our providers and cannot be guaranteed by
              TherapInsights. In cases of data and business loss, TherapInsights
              is not liable.
            </p>

            <h2>Payment Policy</h2>
            <p>There are no payment plans currently for TherapInsights.</p>

            <h2>Disclaimers and Limitation of Liability</h2>
            <p>
              Our Service is provided "as is" and "as available" without any
              warranties of any kind, either express or implied. TherapInsights
              will not be liable for any loss or damage arising from your use of
              our Service.
            </p>

            <h2>Governing Law</h2>
            <p>
              These Terms are governed by the laws of the United Kingdom,
              without regard to its conflict of law principles.
            </p>

            <h2>Contact Information</h2>
            <p>
              For any questions about these Terms, please contact us at
              joshsparkes6@gmail.com.
            </p>

            <h2>Your Rights</h2>
            <p>
              Your Rights As a UK resident, you have the right to access,
              update, or delete your Personal Data. To exercise these rights,
              please contact us using the details below.
            </p>
          </div>
        </Paper>
        <Divider style={{ margin: "20px 0" }} />
        <Paper elevation={0} style={{ padding: "20px" }}>
          <Typography variant="h2">Terms of Use</Typography>
          <Typography variant="body">
            Last Updated: 6 Oct 2023 Welcome to TherapInsights. These Terms of
            Use ("Terms") govern your use of our mobile application and website
            ("Service"). Please read these Terms carefully before using our
            Service. Acceptance of Terms By accessing or using our Service, you
            agree to comply with and be bound by these Terms. If you do not
            agree to these Terms, please do not use our Service. Changes to
            Terms We reserve the right to modify or replace these Terms at any
            time. Your continued use of the Service after any such change
            constitutes your acceptance of the new Terms. Use of Service You
            agree not to use our Service for any illegal or unauthorized
            purpose. You are solely responsible for your conduct and any data,
            text, or other materials that you submit via the Service.
            Intellectual Property All content included in our Service, such as
            text, graphics, logos, and software, is the property of
            TherapInsights or its licensors and is protected by UK and
            international copyright laws. Disclaimers and Limitation of
            Liability Our Service is provided "as is" and "as available" without
            any warranties of any kind, either express or implied.
            TherapInsights will not be liable for any loss or damage arising
            from your use of our Service. Governing Law These Terms are governed
            by the laws of the United Kingdom, without regard to its conflict of
            law principles. Contact Information For any questions about these
            Terms, please contact us at joshsparkes6@gmail.com.
          </Typography>
        </Paper>
        <Snackbar
          open={submitted}
          autoHideDuration={6000}
          onClose={() => setSubmitted(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={() => setSubmitted(false)} severity="success">
            Your feedback has been submitted!
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default Policy;
