import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/App.css";
import Screenshot from "./images/Screenshot.png";
import Footer from "./components/footer";
import Modal from "@mui/material/Modal";
import Waitlist from "./components/waitlist";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { auth } from "../src/firebase";
import ReactGA from "react-ga4";
import Menu from "./components/menu";
import TryIt from "./components/tryIt";
import Transcription from "./images/transcription.png";
import Summarise from "./images/summarise.png";
import Security from "./images/security.png";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [selected, setSelected] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleGetStartedClick = () => {
    ReactGA.event({
      category: "Button Click",
      action: "Clicked Get Started Free",
      label: "Get Started Button",
    });
  };

  const handleSignUpClick = () => {
    ReactGA.event({
      category: "Button Click",
      action: "Clicked Sign Up",
      label: "Sign Up Button",
    });
  };

  const handleSignInClick = () => {
    ReactGA.event({
      category: "Button Click",
      action: "Clicked Sign In",
      label: "Sign In Button",
    });
  };

  const handleBookDemoClick = () => {
    ReactGA.event({
      category: "Button Click",
      action: "Clicked Book a Demo",
      label: "Book a Demo Button",
    });
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // ... authentication state change logic
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const items = [
    {
      name: "Transcription",
      content:
        "AI-powered session summaries that distill key points and critical observations. Our intelligent system identifies and highlights important elements like trigger words and agreed next actions, making post-session follow-ups more efficient.",
    },
    {
      name: "Summaries",
      content:
        "AI-powered session summaries that distill key points and critical observations. Our intelligent system identifies and highlights important elements like trigger words and agreed next actions, making post-session follow-ups more efficient.",
    },
    {
      name: "Efficiency",
      content:
        "Dramatically reduce the workload of therapists by automating documentation. With TherapInsights, you can dedicate more time to your clients, secure in the knowledge that all session details are being accurately captured and organized.",
    },
  ];

  const items2 = [
    {
      name: "Time-saving",
      content:
        "Liberate yourself from manual note-taking. Our tool automates and streamlines documentation, allowing you to fully engage with your clients during sessions, confident that all necessary notes are being taken care of in the background.",
    },
    {
      name: "Precision",
      content:
        "State-of-the-art AI transcription ensures unparalleled accuracy in capturing session details. Our technology is constantly learning and evolving, ensuring that the precision of transcriptions improves continually.",
    },
    {
      name: "Continual Learning",
      content:
        "Our tool doesn’t just transcribe; it learns. It adapts over time, refining its insights to deliver consistently improved results, tailored to the unique demands of therapeutic sessions.",
    },
  ];

  const items3 = [
    {
      name: "Data Security",
      content:
        "We uphold the highest standards of data protection and confidentiality. TherapInsights is built on secure, reliable platforms, ensuring that all client and session data remain private and protected.",
    },
    {
      name: "Integration",
      content:
        "Designed to work seamlessly with Zoom, our tool integrates effortlessly into your existing telehealth setup. This synergy ensures a smooth, uninterrupted experience for both therapists and clients.",
    },
    {
      name: "Intuitive Display",
      content:
        "Our interface is designed for simplicity and ease of use. Transcripts and summaries are presented in a clear, user-friendly format, making it easy to review and retrieve session insights.",
    },
  ];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div id="home">
      <Menu
        loggedIn={loggedIn}
        handleSignUpClick={handleSignUpClick}
        handleSignInClick={handleSignInClick}
      />
      <div className="container1">
        <h1 className="title">
          Focus on your sessions.
          <br /> We'll do your notes.
        </h1>
        <h2 className="subtitle">
          The AI-assisted platform for mental health professionals with
          automated notes.
        </h2>
        <div className="btn-container">
          <Link
            to="/signUp"
            className="get-started-btn"
            onClick={handleGetStartedClick}
          >
            Get Started Free
          </Link>
          <Link
            onClick={() => {
              handleBookDemoClick();
              handleModalOpen();
            }}
            className="demo-btn"
          >
            Book a Demo
            <ArrowOutwardIcon style={{ marginLeft: "10px" }} />
          </Link>
        </div>
      </div>
      <div className="container1b">
        <img
          width={"70%"}
          height={"auto"}
          className={"lpimage"}
          style={{
            borderWidth: "1px",
            borderColor: "gray",
            borderStyle: "solid",
            borderRadius: "10px",
          }}
          src={Screenshot}
          alt="Softkraft process"
        />
      </div>
      <div className="container2">
        <div className="problem-section">
          <div className="h3AndP" id="solution">
            <h2 className="section-header">Real-time Transcription</h2>
            <h3>Documenting Every Moment</h3>
            <ul>
              {items.map((item, index) => (
                <li
                  key={index}
                  style={{
                    backgroundColor:
                      selected === index ? "#fff" : "rgba(255,255,255,.2)",
                  }}
                  onClick={() => setSelected(index)}
                >
                  <strong
                    style={{
                      fontSize: "1.4rem",
                    }}
                  >
                    {item.name}
                  </strong>
                  <div
                    className="bubble-body"
                    style={{ display: selected === index ? "block" : "none" }}
                  >
                    {item.content}
                  </div>
                </li>
              ))}
            </ul>
            <Link className="section-btn" to="/signUp">
              Start
            </Link>
          </div>
          <img
            width={"350px"}
            height={"auto"}
            className={"lpimage"}
            style={{
              borderWidth: "1px",
              borderColor: "gray",
              borderStyle: "solid",
              borderRadius: "10px",
            }}
            src={Transcription}
            alt="Softkraft process"
          />
        </div>

        <div className="problem-section2">
          <div className="h3AndP">
            <h2 className="section-header">AI-Enhanced Summaries</h2>
            <h3>Swiftly Capture the Essence of Each Session</h3>

            <ul>
              {items2.map((item, index) => (
                <li
                  key={index}
                  style={{
                    backgroundColor:
                      selected === index ? "#fff" : "rgba(255,255,255,.2)",
                  }}
                  onClick={() => setSelected(index)}
                >
                  <strong
                    style={{
                      fontSize: "1.4rem",
                    }}
                  >
                    {item.name}
                  </strong>
                  <div
                    className="bubble-body"
                    style={{ display: selected === index ? "block" : "none" }}
                  >
                    {item.content}
                  </div>
                </li>
              ))}
            </ul>
            <Link className="section-btn" to="/signUp">
              Start
            </Link>
          </div>
          <img
            width={"350px"}
            height={"auto"}
            className={"lpimage"}
            style={{ border: "black 1px solid", borderRadius: "10px" }}
            src={Summarise}
            alt="Softkraft process"
          />
        </div>

        <div className="problem-section3">
          <div className="h3AndP">
            <h2 className="section-header">Unparalleled Data Security</h2>
            <h3>Guaranteed Protection for Every Session</h3>
            <ul>
              {items3.map((item, index) => (
                <li
                  key={index}
                  style={{
                    backgroundColor:
                      selected === index ? "#fff" : "rgba(255,255,255,.2)",
                  }}
                  onClick={() => setSelected(index)}
                >
                  <strong
                    style={{
                      fontSize: "1.4rem",
                    }}
                  >
                    {item.name}
                  </strong>
                  <div
                    className="bubble-body"
                    style={{ display: selected === index ? "block" : "none" }}
                  >
                    {item.content}
                  </div>
                </li>
              ))}
            </ul>
            <Link className="section-btn" to="/signUp">
              Start
            </Link>
          </div>
          <img
            width={"350px"}
            height={"auto"}
            style={{ border: "black 1px solid", borderRadius: "10px" }}
            src={Security}
            alt="PMAI Logo"
          />
        </div>
        <TryIt />
      </div>
      <Footer />
      <Modal open={openModal} onClose={handleModalClose}>
        <Waitlist handleModalClose={handleModalClose} />
      </Modal>
      {/* <PriorityModal
        isOpen={showPriorityModal}
        onClose={() => setShowPriorityModal(false)}
        onPrioritySelect={handlePrioritySelect}
      /> */}
    </div>
  );
}

export default App;
