import React from "react";
import { Typography, Container, Paper } from "@mui/material";
import SignUpImg from "../images/SignUp.png";
import ConnectToZoomImg from "../images/ConnectToZoom.png";
import IntegrateImg from "../images/Integrate.png";
import StartSessionImg from "../images/StartSession.png";
import RecordSessionImg from "../images/RecordSession.png";
import WaitForSummaryImg from "../images/WaitForSummary.png";
import Summarised from "../images/Summarised.png";
import NavBarDocs from "../components/navBarDocs";

const Docs = () => {
  return (
    <div>
      <NavBarDocs />
      <Container
        style={{
          marginLeft: "305px",
          marginTop: "30px",
          maxWidth: `calc(100% - 305px)`,
        }}
      >
        <Paper elevation={0} style={{ padding: "0px" }}>
          <Typography
            style={{ fontFamily: '"Montserrat", sans-serif' }}
            variant="h4"
          >
            User Guide for TherapInsights
          </Typography>
          <Typography id="getting-started" variant="subtitle2">
            Last Updated: 6 Oct 2023
          </Typography>
          <br />
          <br />
          <Typography
            style={{ fontFamily: '"Montserrat", sans-serif' }}
            variant="body"
          >
            <strong style={{ fontWeight: 500, fontFamily: '"Montserrat", sans-serif' }}>
              Getting started with TherapInsights
            </strong>
            <Typography
              style={{
                marginTop: "20px",
                fontFamily: '"Montserrat", sans-serif',
              }}
            >
              1. Create a free TherapInsights account.
            </Typography>
            <img
              src={SignUpImg}
              alt="Sign Up"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <Typography style={{ fontFamily: '"Montserrat", sans-serif' }}>
              2. Connect to your Zoom Account (Note: You must have a Zoom Pro
              account).
            </Typography>
            <img
              src={ConnectToZoomImg}
              alt="Connect to Zoom"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <Typography style={{ fontFamily: '"Montserrat", sans-serif' }}>
              3. Authorize TherapInsights on Zoom.
            </Typography>
            <img
              src={IntegrateImg}
              alt="Integrate"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <Typography style={{ fontFamily: '"Montserrat", sans-serif' }}>
              4. Start a Zoom Meeting.
            </Typography>
            <img
              src={StartSessionImg}
              alt="Start Session"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <Typography style={{ fontFamily: '"Montserrat", sans-serif' }}>
              5. Record the Meeting (ensure you are recording to Cloud
              Recordings).{" "}
            </Typography>
            <img
              src={RecordSessionImg}
              alt="Record Session"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <Typography style={{ fontFamily: '"Montserrat", sans-serif' }}>
              6. Wait for it to Summarize (this can take between 2 and 10
              minutes).
            </Typography>
            <img
              src={WaitForSummaryImg}
              alt="Wait for Summary"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <Typography style={{ fontFamily: '"Montserrat", sans-serif' }}>
              7. Review the Session Summary, Tasks, Trigger Words, Suggested
              Documentation.
            </Typography>
            <img
              src={Summarised}
              alt="Summariseds"
              width="750"
              style={{
                border: "black 2px solid",
                borderRadius: "8px",
                margin: "20px",
              }}
            />
            <br />
            <strong id="zoom">Using the App within Zoom</strong>
            <br />
            <br />
            <strong>How to Access</strong>
            <br />
            After installation, you can find TherapInsights in the Zoom client
            under the "Apps" tab.
            <br />
            <br />
            <strong>Troubleshooting</strong>
            <br />
            - Issue 1: Transcription not working? Make sure you have granted the
            necessary permissions.
            <br />
            - Issue 2: Summary not generating? Ensure you have a Zoom pro
            account to get Cloud recordings.
            <br />
            <br />
            <strong>Uninstalling the App</strong>
            <br />
            1. Go to Zoom App Marketplace: Open your web browser and navigate to
            the Zoom App Marketplace.
            <br />
            2. Locate Our App: Search for "TherapInsights" in the search bar.
            <br />
            3. Uninstall: Click on "Manage" then choose "Installed Apps." Find
            TherapInsights and click "Uninstall."
            <br />
            <br />
            <strong>Contact Information</strong>
            <br />
            For any questions or issues regarding TherapInsights, please contact
            us at joshsparkes6@gmail.com.
            <br />
            <br />
            We hope this guide helps you make the most of TherapInsights. For
            further assistance, don't hesitate to contact our support team.
            <br />
            <br />
          </Typography>
          <Typography style={{ marginBottom: "20px" }}>
            <strong>Usage Prerequisites</strong>
            <br />
            - You must have Zoom Pro account to use TherapInsights. This is
            because you need access to Cloud Recordings to use the Transcripts!
            <br />
            - For Automated Transcription: Make sure you have granted the
            necessary permissions.
            <br />
            - For Meeting Summary: Make sure your Zoom session has more than one
            participant.
            <br />
            <br />
            <strong>Implications of Removing the App</strong>
            <br />
            Uninstalling the app will remove all your stored transcriptions and
            meeting summaries. This action is irreversible.
            <br />
            <br />
            <strong id="contact-support">Contact Support</strong>
            <br />
            Our support team is available from 9am to 5pm UTC. Expect a first
            response within 24 hours.
            <ul>
              <li>
                <a href="mailto:joshsparkes6@gmail.com">Email Support</a>
              </li>
              <li>
                <a href="tel:+44 7493 291 667">
                  Phone Support: +44 7493 291 667
                </a>
              </li>
            </ul>
          </Typography>
        </Paper>
      </Container>
    </div>
  );
};

export default Docs;
