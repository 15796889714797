import React, { useEffect, useState } from "react";
// import { useParams } from 'react-router-dom';

const ZoomCallback = () => {
  // const {  } = useParams();

  // State variables for access token and refresh token
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  // Define the function to exchange the authorization code for tokens
  const sendTokenExchangeRequest = (authorizationCode) => {
    // Construct the POST request to your server (you can use fetch or Axios)
    return fetch("/token-exchange", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: authorizationCode,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Token exchange request failed");
        }
        return response.json();
      })
      .then((data) => {
        // Store the tokens in state
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);

        // Return the tokens received from the server
        return {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        };
      });
  };

  // Function to handle the Zoom callback
  const handleZoomCallback = (authorizationCode) => {
    // Call the function to exchange the authorization code for tokens
    sendTokenExchangeRequest(authorizationCode)
      .then((tokens) => {
        // Tokens are already stored in state
        console.log("Access token:", accessToken);
        console.log("Refresh token:", refreshToken);
      })
      .catch((error) => {
        // Handle any errors that occurred during token exchange
        console.error("Token exchange error:", error);
      });
  };

  useEffect(() => {
    // Check if there's an authorization code in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get("code");

    if (authorizationCode) {
      // Call the function to handle the Zoom callback
      handleZoomCallback(authorizationCode);
    }
  });

  return <div>{/* Display a loading indicator or message here */}</div>;
};

export default ZoomCallback;
