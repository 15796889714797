import React, { useEffect, useState } from "react";
import Navbar from "../components/navBar";
import {
  Typography,
  Container,
  Link,
  CircularProgress, // Import this for loading indicator
} from "@mui/material";
import firebase from "firebase/compat/app";
import "../styles/styles.css";
import DragHandleIcon from "@mui/icons-material/DragHandle";


const firebaseConfig = {
  apiKey: "AIzaSyB3lhjopNhTn2JcbrCdlDeiayR72VqQb5s",
  authDomain: "therapinsights.firebaseapp.com",
  projectId: "therapinsights",
  storageBucket: "therapinsights.appspot.com",
  messagingSenderId: "236965647773",
  appId: "1:236965647773:web:fbdf0ca3edb007023f4b74",
  measurementId: "G-DWLFNPHQND",
};

firebase.initializeApp(firebaseConfig);
//   const db = firebase.firestore();

function Calendar() {
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [firebaseUID, setFirebaseUID] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setFirebaseUID(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchMeetings = async () => {
      setIsLoading(true); // Set loading to true before fetching
      try {
        const zoomUserId = firebaseUID;

        const res = await fetch(
          `https://therapinsights.onrender.com/zoom/upcoming_meetings/${zoomUserId}`
        );

        if (res.ok) {
          const data = await res.json();
          if (data.status === "success") {
            setUpcomingMeetings(data.meetings);
          }
        } else {
          console.error(`Failed to fetch meetings. HTTP Status: ${res.status}`);
        }
      } catch (error) {
        console.error("Failed to fetch meetings:", error);
      }
      setIsLoading(false); // Set loading to false after fetching
    };

    if (firebaseUID) {
      fetchMeetings();
    }
  }, [firebaseUID]);
  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  return (
    <div>
      <Navbar isVisible={isNavbarVisible} toggleNavbar={toggleNavbar} />
      <Container className="dashboard-container">
        <div className="mobile-navbar-toggle" onClick={toggleNavbar}>
          <DragHandleIcon />
        </div>
        <Typography
          variant="h1"
          style={{
            fontSize: "48px",
            marginTop: "30px",
            marginBottom: "20px",
            color: "#6D7580",
          }}
        >
          Upcoming Meetings
        </Typography>
        <button
          style={{
            padding: "10px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "8px",
            fontSize: "18px",
            marginBottom: "20px",
          }}
          onClick={() =>
            window.open("https://zoom.us/meeting/schedule", "_blank")
          }
        >
          Create New Meeting
        </button>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <ul>
            {upcomingMeetings.map((meeting) => (
              <li style={{ fontSize: "18px" }} key={meeting.id}>
                <span>
                  <span style={{ fontSize: "24px", fontWeight: "500" }}>
                    {meeting.topic}{" "}
                  </span>
                  <br /> {new Date(meeting.start_time).toLocaleString()}
                </span>
                <Link
                  style={{
                    padding: "15px",
                    width: "200px",
                    backgroundColor: "#2d8cff",
                    color: "white",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                  onClick={() => window.open(meeting.join_url, "_blank")}
                >
                  Join
                </Link>
              </li>
            ))}
          </ul>
        )}
      </Container>
    </div>
  );
}

export default Calendar;
