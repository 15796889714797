import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Container,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Navbar from "../components/navBar";
import { auth, doc, getDoc, db } from "../firebase";
import Zoom from "../images/zoom.png";
import Teams from "../images/Teams.png";
import DragHandleIcon from "@mui/icons-material/DragHandle";


function MyApps() {
  const [zoomIntegrationEnabled, setZoomIntegrationEnabled] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const docSnapshot = await getDoc(userRef);
        if (docSnapshot.exists()) {
          const zoomUserId = docSnapshot.data().zoom_user_id;
          if (zoomUserId) {
            setZoomIntegrationEnabled(true);
          }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleZoomToggle = () => {
    setZoomIntegrationEnabled(!zoomIntegrationEnabled);
  };

  const handleConnectToZoom = () => {
    const user = auth.currentUser;
    if (user) {
      setZoomIntegrationEnabled(true);
      const firebaseUid = user.uid; // Fetch the Firebase UID

      // Log or debug the firebaseUid to make sure it's not null or undefined
      console.log("Firebase UID:", firebaseUid);

      const zoomAuthorizationUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=ybKPAft6SSyBirKyRpWw&redirect_uri=https://therapinsights.onrender.com/zoom/callback&state=${firebaseUid}`;

      // Log the complete URL to debug
      console.log("Zoom Authorization URL:", zoomAuthorizationUrl);

      window.location.href = zoomAuthorizationUrl;
    } else {
      console.log("User not authenticated.");
    }
  };

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  return (
    <div>
      <Navbar isVisible={isNavbarVisible} toggleNavbar={toggleNavbar} />
      <Container className="dashboard-container">
        <div className="mobile-navbar-toggle" onClick={toggleNavbar}>
          <DragHandleIcon />
        </div>
        <Box my={4}>
          <Typography
            variant="h1"
            style={{
              fontSize: "48px",
              marginTop: "30px",
              marginBottom: "25px",
              color: "#6D7580",
            }}
          >
            Integrations
          </Typography>

          <Paper elevation={0}>
            <Box p={4} display="flex" flexDirection="row" alignItems="center">
              <img
                width={"auto"}
                height={"100px"}
                style={{}}
                src={Zoom}
                alt="Softkraft process"
              />
              {zoomIntegrationEnabled ? (
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    color: "#6D7580",
                    padding: "10px",
                    border: "2px solid, #6D7580",
                    borderRadius: "8px",
                  }}
                >
                  {" "}
                  Connected{" "}
                </span>
              ) : (
                <span
                  onClick={handleConnectToZoom}
                  style={{
                    marginLeft: "20px",
                    cursor: "pointer",
                    backgroundColor: "#6D7580",
                    color: "white",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  Connect
                </span>
              )}
              <Box style={{ marginLeft: "30px" }} mb={2} mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={zoomIntegrationEnabled}
                      onChange={handleZoomToggle}
                      name="zoomIntegration"
                      color="primary"
                    />
                  }
                />
              </Box>
            </Box>
          </Paper>

          <Paper elevation={1}>
            <Box p={4}>
              <img
                width={"auto"}
                height={"100px"}
                style={{}}
                src={Teams}
                alt="Softkraft process"
              />
              <span
                style={{
                  backgroundColor: "#fff",
                  color: "teal",
                  padding: "10px",
                  border: "2px solid, #6D7580",
                  borderRadius: "8px",
                }}
              >
                {" "}
                Coming Soon{" "}
              </span>
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default MyApps;
