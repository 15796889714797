import React, { useState } from "react";
import "../styles/website.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function TherapyLandingPage() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://therapinsights.onrender.com/submit-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully");
        setOpenSnackbar(true); // Open the Snackbar upon successful submission
      } else {
        console.log("Form submission failed");
        // Handle error
      }
    } catch (error) {
      console.error("There was an error submitting the form", error);
    }
  };

  return (
    <div
      className="tlp-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <h1
        style={{
          fontSize: "2rem",
          color: "black",
          fontWeight: "bold",
          fontFamily: '"Montserrat", sans-serif',
          marginBottom: "40px",
          textAlign: "center",
        }}
      >
        100% Free Cognitive Behavioural Therapy (CBT)
      </h1>
      <h2
        style={{
          fontSize: "1rem",
          color: "black",
          fontWeight: "bold",
          fontFamily: '"Montserrat", sans-serif',
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        With a certified, UK-based CBT therapist{" "}
      </h2>
      <p
        style={{
          fontSize: "0.9rem",
          color: "black",
          fontFamily: '"Montserrat", sans-serif',
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        Join us for a unique opportunity to explore Cognitive Behavioral Therapy
        (CBT) with a qualified therapist. <br/>Our free sessions offer a path to
        better mental health, designed for anyone seeking positive change.
      </p>
      <form
        className="tlp-form"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
        onSubmit={handleSubmit}
      >
        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: '"Montserrat", sans-serif',
          }}
        >
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            style={{
              flexGrow: 1,
              marginLeft: "10px",
              borderRadius: "8px",
              height: "50px",
              borderColor: "lightgrey",
              paddingLeft: "10px",
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: '"Montserrat", sans-serif',
          }}
        >
          Email Address:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            style={{
              flexGrow: 1,
              marginLeft: "10px",
              borderRadius: "8px",
              height: "50px",
              borderColor: "lightgrey",
              paddingLeft: "10px",
            }}
          />
        </label>
        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: '"Montserrat", sans-serif',
          }}
        >
          Phone Number:
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            style={{
              flexGrow: 1,
              marginLeft: "10px",
              borderRadius: "8px",
              height: "50px",
              borderColor: "lightgrey",
              paddingLeft: "10px",
            }}
          />
        </label>
        <button
          style={{
            height: "50px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "8px",
            fontWeight: "bold",
            width: '50%',
            alignSelf:'center',
            fontFamily: '"Montserrat", sans-serif',
          }}
          type="submit"
        >
          Submit
        </button>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity="success"
          elevation={6}
          variant="filled"
        >
          Submission sent, we'll be in touch!
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default TherapyLandingPage;
