import React from "react";
import { Route, Routes } from "react-router-dom";
import SignUp from "./pages/signUp";
import SignIn from "./pages/signIn";
import Pricing from "./pages/pricing";
import Dashboard from "./pages/dashboard";
import App from "./App";
import Settings from "./pages/settings";
import MyApps from "./pages/myApps";
import Conversation from "./pages/conversation";
import ZoomCallback from "./pages/zoomCallback";
import Calendar from "./pages/calendar";
import ZoomSuccess from "./pages/zoomSuccess";
import Contact from "./pages/contact";
import Policy from "./pages/policy";
import Docs from "./pages/docs";
import Patients from "./pages/patients";
import Solution from "./pages/solution";
import Company from "./pages/company";
import TherapyLandingPage from "./pages/therapyLandingPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/signIn" element={<SignIn />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/myApps" element={<MyApps />} />
      <Route path="/conversation/:meeting_id" element={<Conversation />} />
      <Route path="/zoomCallback" element={<ZoomCallback />} />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/zoomSuccess" element={<ZoomSuccess />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/patients" element={<Patients />} />
      <Route path="/company" element={<Company />} />
      <Route path="/solution" element={<Solution />} />
      <Route path="/therapyLandingPage" element={<TherapyLandingPage />} />
    </Routes>
  );
};

export default AppRoutes;
