import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import "../styles/menu.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function Menu({ loggedIn, handleSignUpClick, handleSignInClick }) {
  return (
    <div className="home-navbar">
      <Link to="/">
        <img src={Logo} alt="PMAI Logo" className="spinning-logo" />
      </Link>
      <div className="menu-links">
        {loggedIn ? (
          <div className="menu-logged-in">
            <a href="/company" className="menu-link">
              Company
            </a>
            <a href="/solution" className="menu-link">
              Solution
            </a>
            {/* <a href="/pricing" className="menu-link">
              Pricing
            </a> */}
            <a
              href="/docs"
              className="menu-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </a>
            <Link to="/dashboard" className="menu-link">
              Dashboard
            </Link>
          </div>
        ) : (
          <div className="menu-logged-out">
            <a href="/company" className="menu-link">
              Company
            </a>
            <a href="/solution" className="menu-link">
              Solution
            </a>
            {/* <a href="/pricing" className="menu-link">
              Pricing
            </a> */}
            <a
              href="/docs"
              className="menu-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </a>
            <Link
              to="/signIn"
              className="menu-link-login"
              onClick={handleSignInClick}
            >
              Log In
            </Link>
            <Link
              to="/signUp"
              className="menu-link-start"
              onClick={handleSignUpClick}
            >
              Start
              <KeyboardArrowRightIcon />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Menu;
