import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Container,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Navbar from "../components/navBar";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"; // Import Firestore
import { auth, getDoc, doc, updateDoc } from "../firebase";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Checkbox, FormControlLabel } from "@mui/material";
import Joyride from "react-joyride";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const firebaseConfig = {
  apiKey: "AIzaSyB3lhjopNhTn2JcbrCdlDeiayR72VqQb5s",
  authDomain: "therapinsights.firebaseapp.com",
  projectId: "therapinsights",
  storageBucket: "therapinsights.appspot.com",
  messagingSenderId: "236965647773",
  appId: "1:236965647773:web:fbdf0ca3edb007023f4b74",
  measurementId: "G-DWLFNPHQND",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function Conversation() {
  const [open, setOpen] = useState(false);
  const { meeting_id } = useParams();
  const [title, setTitle] = useState(""); // State to store 'title' from Firestore
  const [summary, setSummary] = useState(""); // State to store 'title' from Firestore
  const [createdAt, setCreatedAt] = useState(""); // State to store 'created_at' from Firestore
  const [status, setStatus] = useState("");
  const [user, setUser] = useState(null); // State to store the user object
  const [nextSteps, setNextSteps] = useState([]);
  const [triggerPhrases, setTriggerPhrases] = useState([]);
  const [suggestedDocumentation, setSuggestedDocumentation] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSummary, setEditedSummary] = useState("");
  const [isEditingNextSteps, setIsEditingNextSteps] = useState(false);
  const [editedNextSteps, setEditedNextSteps] = useState("");
  const [therapistThoughts, setTherapistThoughts] = useState("");
  const [isEditingThoughts, setIsEditingThoughts] = useState(false);
  const [editedThoughts, setEditedThoughts] = useState("");
  const [isAddingNewDoc, setIsAddingNewDoc] = useState(false);
  const [newDoc, setNewDoc] = useState("");
  const [patientName, setPatientName] = useState("");
  const [isEditingPatient, setIsEditingPatient] = useState(false);
  const [editedPatientName, setEditedPatientName] = useState("");
  const [run, setRun] = useState(false); // Default state is 'false'
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const [confirmCopyOpen, setConfirmCopyOpen] = useState(false);

  useEffect(() => {
    const tourCompleted = localStorage.getItem("tourCompleted");
    if (tourCompleted !== "yes") {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem("tourCompleted", "yes");
      setRun(false);
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  const handleCopySummary = () => {
    setConfirmCopyOpen(true);
  };

  const handleConfirmCopy = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        console.log("Summary copied to clipboard");
      })
      .catch((err) => {
        console.log("Failed to copy text: ", err);
      });
    setConfirmCopyOpen(false);
  };

  const handleCloseConfirmCopy = () => {
    setConfirmCopyOpen(false);
  };

  const handleEditToggle = () => {
    if (!isEditing) {
      setEditedSummary(summary);
    }
    setIsEditing(!isEditing);
  };

  const handleSaveSummary = async () => {
    try {
      const documentId = getDocumentId();
      const docRef = doc(db, "meetings", documentId);
      await updateDoc(docRef, {
        summary: editedSummary,
      });
      setSummary(editedSummary);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating summary:", error);
    }
  };

  const handleEditNextStepsToggle = () => {
    if (!isEditingNextSteps) {
      setEditedNextSteps(nextSteps.join("\\n")); // Convert array to newline-separated string
    }
    setIsEditingNextSteps(!isEditingNextSteps);
  };

  const handleSaveNextSteps = async () => {
    try {
      const documentId = getDocumentId();
      const docRef = doc(db, "meetings", documentId);
      const newNextSteps = editedNextSteps.split("\n"); // Convert the multiline string back into an array
      await updateDoc(docRef, {
        next_steps: newNextSteps,
      });
      setNextSteps(newNextSteps);
      setIsEditingNextSteps(false);
    } catch (error) {
      console.error("Error updating next steps:", error);
    }
  };

  // Function to save therapist thoughts
  const handleSaveThoughts = async () => {
    try {
      const documentId = getDocumentId();
      const docRef = doc(db, "meetings", documentId);
      await updateDoc(docRef, {
        therapist_thoughts: editedThoughts,
      });
      setTherapistThoughts(editedThoughts);
      setIsEditingThoughts(false);
    } catch (error) {
      console.error("Error updating therapist thoughts:", error);
    }
  };

  // Function to toggle editing state for therapist thoughts
  const handleEditThoughtsToggle = () => {
    if (!isEditingThoughts) {
      setEditedThoughts(therapistThoughts);
    }
    setIsEditingThoughts(!isEditingThoughts);
  };

  // Function to add new suggested documentation
  const handleAddNewDoc = async () => {
    try {
      const documentId = getDocumentId();
      const docRef = doc(db, "meetings", documentId);
      const updatedDocs = [...suggestedDocumentation, newDoc];
      await updateDoc(docRef, {
        suggested_documentation: updatedDocs,
      });
      setSuggestedDocumentation(updatedDocs);
      setIsAddingNewDoc(false);
    } catch (error) {
      console.error("Error adding new documentation:", error);
    }
  };

  const getDocumentId = () => {
    const url = window.location.href;
    const urlArray = url.split("/");
    const documentId = urlArray[urlArray.length - 1];
    console.log(documentId);
    return documentId;
  };

  useEffect(() => {
    if (!user) return;

    const fetchDocument = async () => {
      const documentId = getDocumentId();
      const docRef = doc(db, "meetings", documentId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const timestamp = new Date(docData.end_time);
        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
          timestamp
        );

        setTitle(docData.title);
        setCreatedAt(formattedDate);
        setStatus(docData.status);
        setSummary(docData.summary);
        setNextSteps(docData.next_steps || []);
        setTriggerPhrases(docData.trigger_phrases || []);
        setSuggestedDocumentation(docData.suggested_documentation || []);
        setPatientName(docData.patient || ""); // Add this line
      } else {
        console.log("Document does not exist!");
      }
    };
    fetchDocument();
  }, [user]);

  const handleDownloadTranscription = async () => {
    try {
      const response = await fetch(
        `https://therapinsights.onrender.com/download_transcription/${meeting_id}`
      );
      if (response.ok) {
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transcription.vtt");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Failed to fetch transcription:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading transcription:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAsDone = async () => {
    try {
      const documentId = getDocumentId().toString(); // Ensure it's a string
      const docRef = doc(db, "meetings", documentId);

      await updateDoc(docRef, {
        status: "Done",
      });

      console.log("Document updated successfully");
      setStatus("Done");
      handleClose();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleEditPatientToggle = () => {
    if (!isEditingPatient) {
      setEditedPatientName(patientName);
    }
    setIsEditingPatient(!isEditingPatient);
  };

  const handleSavePatientName = async () => {
    try {
      const documentId = getDocumentId();
      const docRef = doc(db, "meetings", documentId);
      await updateDoc(docRef, {
        patient: editedPatientName,
      });
      setPatientName(editedPatientName);
      setIsEditingPatient(false);
    } catch (error) {
      console.error("Error updating patient name:", error);
    }
  };

  const steps = [
    {
      target: "#summary",
      content:
        "This is a summary of your zoom meeting, here is captured all the important information you need to know.",
      title: "Summary",
    },
    {
      target: "#actions",
      content:
        "Here we have recorded all of the actions and next steps from the session.",
      title: "Actions",
    },
    {
      target: "#phrases",
      content:
        "Key and trigger phrases that you need to be aware was mentioned during the session.",
      title: "Key Phrases",
    },
    {
      target: "#docs",
      content:
        "A list of suggested documentation that needs to be completed for this session.",
      title: "Suggested Docs",
    },
    {
      target: "#done",
      content:
        "When you have done everything needed for this session, you can mark it as 'Done'",
      title: "Mark as Done",
    },
  ];

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const ConfirmCopyModal = () => (
    <Dialog open={confirmCopyOpen} onClose={handleCloseConfirmCopy}>
      <DialogContent>
        <DialogContentText variant="h6">
          Have you checked the summary and agree with the content?
        </DialogContentText>
        <DialogContentText variant="body">
          Make sure you agree with the summary before submitting it to any other
          systems.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmCopy} color="primary">
          Yes, I have checked
        </Button>
        <Button onClick={handleCloseConfirmCopy} color="secondary">
          No, I will check
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      <Navbar isVisible={isNavbarVisible} toggleNavbar={toggleNavbar} />
      <Container className="dashboard-container">
        <div className="mobile-navbar-toggle" onClick={toggleNavbar}>
          <DragHandleIcon />
        </div>
        <Box className="conversation-box">
          <Box className="conversation-box-sub">
            <Typography
              variant="h1"
              style={{ fontSize: "48px", marginTop: "30px", color: "#6D7580" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: "24px",
                color: "#858C94",
                marginBottom: "10px",
              }}
            >
              {createdAt}
            </Typography>

            <Paper
              id="summary"
              style={{
                height: "300px",
                marginBottom: "0px",
                padding: "10px",
                border: "none",
                boxShadow: "none",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "18px",
                    fontWeight: "lighter",
                    color: "#2B3A4B",
                  }}
                >
                  Summary
                </Typography>
                <Box>
                  <IconButton onClick={handleCopySummary} size="small">
                    <FileCopyOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={handleEditToggle} size="small">
                    {isEditing ? (
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        onClick={handleSaveSummary}
                      />
                    ) : (
                      <EditOutlinedIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <div
                style={{
                  borderBottom: "1px solid lightgrey",
                  margin: "10px 0",
                }}
              ></div>
              {isEditing ? (
                <textarea
                value={editedSummary}
                onChange={(e) => setEditedSummary(e.target.value)}
                style={{
                  width: "740px",
                  height: '230px',
                  boxSizing: 'border-box',
                  fontSize: "14px",
                  fontWeight: "300",
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #ced4da",
                  padding: "10px",
                  borderRadius: "4px",
                  overflow: "auto",
                  resize: "vertical", // Allows the user to resize the textarea vertically
                }}
              />
              ) : summary ? (
                <Typography
                  style={{
                    fontSize: "14px",
                    height: "83%",
                    width: "100%",
                    overflow: "scroll",
                    fontWeight: "300",
                  }}
                >
                  {summary}
                </Typography>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      textAlign: "center",
                    }}
                  >
                    Summarisation in progress, please wait... <br />
                    (You can add some notes in Therapist Thoughts below)
                  </Typography>
                  <CircularProgress style={{ marginTop: "30px" }} />
                </div>
              )}
            </Paper>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h6"
                style={{
                  fontSize: "18px",
                  fontWeight: "lighter",
                  color: "#2B3A4B",
                }}
              >
                Therapist Thoughts{" "}
                <span style={{ fontSize: "12px" }}>
                  (Add your own notes to this session if needed)
                </span>
              </Typography>
              <IconButton onClick={handleEditThoughtsToggle} size="small">
                {isEditingThoughts ? (
                  <CheckCircleOutlineIcon
                    fontSize="small"
                    onClick={handleSaveThoughts}
                  />
                ) : (
                  <EditOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
            <div
              style={{
                borderBottom: "1px solid lightgrey",
                margin: "10px 0",
              }}
            ></div>
            <div
              style={{
                margin: "10px 0",
                fontSize: "14px",
                fontWeight: "300",
              }}
            ></div>
            {isEditingThoughts ? (
              <TextField
                fullWidth
                multiline
                rows={2}
                value={editedThoughts}
                onChange={(e) => setEditedThoughts(e.target.value)}
                InputProps={{
                  style: {
                    overflow: "auto",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "300",
                    backgroundColor: "#f2f2f2",
                  },
                }}
              />
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  marginBottom: "20px",
                }}
              >
                {therapistThoughts}
              </Typography>
            )}
            <Box display="flex" id="actions" flexDirection="column">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "18px",
                    fontWeight: "lighter",
                    color: "#2B3A4B",
                  }}
                >
                  Actions / Next Steps
                </Typography>
                <IconButton onClick={handleEditNextStepsToggle} size="small">
                  {isEditingNextSteps ? (
                    <CheckCircleOutlineIcon
                      fontSize="small"
                      onClick={handleSaveNextSteps}
                    />
                  ) : (
                    <AddCircleOutlineIcon fontSize="small" />
                  )}
                </IconButton>
              </Box>
              <div
                style={{
                  borderBottom: "1px solid lightgrey",
                  margin: "10px 0",
                }}
              ></div>

              {isEditingNextSteps ? (
                <TextField
                  fullWidth
                  multiline
                  value={editedNextSteps}
                  onChange={(e) => setEditedNextSteps(e.target.value)}
                  InputProps={{
                    style: {
                      overflow: "auto",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "300",
                      backgroundColor: "#f2f2f2",
                    },
                  }}
                />
              ) : (
                <ul
                  style={{
                    fontSize: "14px",
                    overflow: "scroll",
                    fontWeight: "300",
                    paddingInlineStart: "20px",
                  }}
                >
                  {nextSteps.map((item, index) => (
                    <li
                      key={index}
                      style={{ border: "none", padding: "5px 0" }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          </Box>

          {/* Column 2 */}

          <Box className="command-center">
            <Paper
              style={{
                height: "355px",
                marginTop: "5px",
                padding: "10px",
                border: "none",
                boxShadow: "none",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                style={{
                  backgroundColor: "#f2f2f2",
                  border: "lightgrey 1px solid",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    backgroundColor: "white",
                    border: "lightgrey 1px solid",
                    borderRadius: "8px",
                    fontSize: "24px",
                    color: "green",
                    marginBottom: "2px",
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  {status}
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    id="done"
                    style={{
                      backgroundColor: "#4CAB61",
                      color: "#ffffff",
                      marginRight: "10px",
                      fontSize: "12px",
                      borderRadius: "8px",
                    }}
                    onClick={handleOpen}
                  >
                    <CheckCircleOutlineIcon style={{ marginRight: "5px" }} />{" "}
                    Mark Done
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      color: "#000000",
                      border: "1px solid #000000",
                      fontSize: "12px",
                    }}
                    onClick={handleDownloadTranscription}
                  >
                    <GetAppIcon style={{ marginRight: "5px" }} /> Download
                    Transcription
                  </Button>
                </Box>
                <Box
                  display="flex"
                  width={"100%"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TextField
                    fullWidth
                    value={isEditingPatient ? editedPatientName : patientName}
                    onChange={(e) => setEditedPatientName(e.target.value)}
                    disabled={!isEditingPatient}
                    placeholder="Enter a patient name"
                    style={{ flexGrow: 1, backgroundColor: "white" }}
                  >
                    {patientName}
                  </TextField>
                  <IconButton
                    onClick={handleEditPatientToggle}
                    size="small"
                    style={{ marginLeft: "0px" }}
                  >
                    {isEditingPatient ? (
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        onClick={handleSavePatientName}
                      />
                    ) : (
                      <EditOutlinedIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Paper
                id="phrases"
                style={{
                  height: "175px",
                  marginBottom: "35px",
                  padding: "10px",
                  border: "none",
                  overflow: "scroll",
                  boxShadow: "none",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "18px",
                    fontWeight: "lighter",
                    color: "#2B3A4B",
                  }}
                >
                  Key / Trigger Phrases
                </Typography>
                <div
                  style={{
                    borderBottom: "1px solid lightgrey",
                    margin: "10px 0",
                  }}
                ></div>
                <ul
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    paddingInlineStart: "20px",
                  }}
                >
                  {triggerPhrases.map((phrase, index) => (
                    <li
                      key={index}
                      style={{ border: "none", padding: "5px 0" }}
                    >
                      {phrase}
                    </li>
                  ))}
                </ul>
              </Paper>

              <Paper
                id="docs"
                style={{
                  height: "250px",
                  marginBottom: "20px",
                  overflow: "scroll",
                  padding: "10px",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "18px",
                      fontWeight: "lighter",
                      color: "#2B3A4B",
                    }}
                  >
                    Suggested Documentation
                  </Typography>
                  <IconButton
                    onClick={() => setIsAddingNewDoc(true)}
                    size="small"
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Box>
                <div
                  style={{
                    borderBottom: "1px solid lightgrey",
                    margin: "10px 0",
                  }}
                ></div>
                {isAddingNewDoc ? (
                  <TextField
                    fullWidth
                    value={newDoc}
                    onChange={(e) => setNewDoc(e.target.value)}
                    onBlur={handleAddNewDoc}
                    autoFocus
                  />
                ) : null}
                <ul
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    paddingInlineStart: "20px",
                  }}
                >
                  {suggestedDocumentation.map((phrase, index) => (
                    <li
                      key={index}
                      style={{ border: "none", padding: "5px 0" }}
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <Typography
                            style={{ fontSize: "14px", fontWeight: "300" }}
                          >
                            {phrase}
                          </Typography>
                        }
                      />
                    </li>
                  ))}
                </ul>
              </Paper>
            </Paper>
          </Box>
        </Box>
      </Container>

      <ConfirmCopyModal />

      {/* Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Have the Risk Factors been reviewed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMarkAsDone} color="primary">
            Yes
          </Button>

          <Button onClick={handleClose} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback}
        continuous={true}
        showSkipButton={true}
      />
    </div>
  );
}

export default Conversation;
