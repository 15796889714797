import React from "react";
import "../styles/website.css";
import Menu from "../components/menu";

function Solution() {
  const backgroundStyle = {
    backgroundImage: "linear-gradient(50deg, #84bdf5, #ccfada)",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div className="solution-container">
      <Menu />
      <div style={backgroundStyle}>
        <section className="solution-intro">
          <h2>TherapInsights: Revolutionizing Mental Health Care</h2>
          <p>
            Introducing TherapInsights - a state-of-the-art solution designed to transform the way therapists interact with technology. Our platform seamlessly integrates with telehealth systems, providing automated session transcriptions and AI-driven summaries.
          </p>
        </section>

        <section className="solution-features">
          <h2>Key Features</h2>
          <ul>
            <li><strong>Automated Transcriptions:</strong> Capture every detail of therapy sessions with our advanced speech-to-text technology.</li>
            <li><strong>AI-Driven Summaries:</strong> Quickly understand key session takeaways with intelligently generated summaries.</li>
            <li><strong>Seamless Integration:</strong> Effortlessly combine TherapInsights with popular platforms like Zoom for a unified experience.</li>
          </ul>
        </section>

        <section className="solution-benefits">
          <h2>Benefits for Therapists</h2>
          <p>
            Our solution empowers therapists to focus on what truly matters - their clients. With TherapInsights, reduce the time spent on documentation, enhance the quality of care, and leverage insights to drive better outcomes.
          </p>
        </section>

        <section className="solution-impact">
          <h2>The Impact of TherapInsights</h2>
          <p>
            TherapInsights is more than a tool; it's a catalyst for change in mental health care. By bridging the gap between therapy and technology, we're not just streamlining processes – we're enhancing the therapeutic journey for both therapists and their clients.
          </p>
        </section>

        <section className="solution-compliance">
          <h2>Commitment to Security and Compliance</h2>
          <p>
            We understand the importance of confidentiality and compliance in mental health care. TherapInsights adheres to the highest standards of data protection, ensuring that sensitive information is always secure.
          </p>
        </section>

        <section className="solution-get-started">
          <h2>Get Started with TherapInsights</h2>
          <p>
            Join the growing number of mental health professionals who are embracing the future of therapy. Get in touch to learn more about how TherapInsights can benefit your practice.
          </p>
          {/* Link to contact or product demo page */}
        </section>
      </div>
    </div>
  );
}

export default Solution;
