import React from "react";
import "../styles/website.css";
import Menu from "../components/menu";

function Company() {
  const backgroundStyle = {
    backgroundImage: "linear-gradient(90deg, #fdcecc, #fed5a1)",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div className="company-container">
      <Menu />
      <div style={backgroundStyle}>
        <section className="company-mission">
          <h2>Who We Are</h2>
          <p>
            We’re on a mission to revolutionize mental health care by enhancing
            therapy sessions with cutting-edge technology. Our goal is to
            empower therapists with tools that maximize their efficiency and
            effectiveness, ultimately improving the lives of those they help.
          </p>
        </section>

        <section className="company-vision">
          <h2>What We Do</h2>
          <p>
            At TherapInsights, we believe in the transformative power of
            technology in mental health care. We provide innovative solutions
            like automated session transcriptions and AI-driven session
            summaries, seamlessly integrated with telehealth platforms like
            Zoom. Our vision is to bridge the gap between therapeutic care and
            technological advancement, ensuring every session counts towards
            better mental health.
          </p>
        </section>

        <section className="company-story">
          <h2>Our Story</h2>
          <p>
            Founded by a team passionate about mental health and technology,
            TherapInsights emerged as a response to the challenges faced by
            mental health professionals. Since our inception, we've been
            dedicated to making mental health care more accessible, efficient,
            and impactful. We are proud to collaborate with therapists, clinics,
            and mental health institutions worldwide, continually adapting to
            the evolving needs of mental health care.
          </p>
        </section>

        <section className="company-team">
          <h2>Our Team</h2>
          <p>
            We are a diverse group of thinkers, innovators, and mental health
            advocates. Our team combines expertise in technology, healthcare,
            and user experience to create solutions that make a real difference.
          </p>
          {/* Team member profiles could be added here */}
        </section>

        <section className="company-values">
          <h2>We Embody Our Values</h2>
          <p>
            Solving real-world problems is at the heart of what we do. We focus
            on creating tools that genuinely improve therapy sessions and
            patient outcomes. Our commitment to innovation, integrity, and
            inclusivity drives us forward every day.
          </p>
          {/* Link to page detailing company values */}
        </section>

        <section className="company-careers">
          <h2>Be Part of Our Mission</h2>
          <p>
            Join us in our journey to transform mental health care. Explore
            career opportunities with TherapInsights and make your mark in a
            field where technology meets compassion.
          </p>
          {/* Link to careers page */}
        </section>

        <section className="company-data-confidentiality">
          <h2>Our Commitment to Data Confidentiality</h2>
          <p>
            Maintaining the confidentiality and security of patient data is
            paramount. At TherapInsights, we adhere to the highest standards of
            data protection to ensure every piece of information is handled with
            the utmost care and respect.
          </p>
          {/* Link to page detailing data confidentiality practices */}
        </section>
      </div>
    </div>
  );
}

export default Company;
