import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Avatar,
  TableHead,
  TableRow,
} from "@mui/material";
import Navbar from "../components/navBar";
import { Link, Route, Routes } from "react-router-dom"; // Import Link and Route
import { auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import firebase from "firebase/compat/app";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import "firebase/compat/firestore";
import Conversation from "./conversation";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import "../styles/styles.css";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const firebaseConfig = {
  apiKey: "AIzaSyB3lhjopNhTn2JcbrCdlDeiayR72VqQb5s",
  authDomain: "therapinsights.firebaseapp.com",
  projectId: "therapinsights",
  storageBucket: "therapinsights.appspot.com",
  messagingSenderId: "236965647773",
  appId: "1:236965647773:web:fbdf0ca3edb007023f4b74",
  measurementId: "G-DWLFNPHQND",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function Dashboard() {
  const [firstName, setFirstName] = React.useState("");
  const [user, setUser] = useState(null);
  const [numConversations, setNumConversations] = useState(0);
  const [meetings, setMeetings] = useState([]);
  const [pendingMeetings, setPendingMeetings] = useState(0);
  const [zoomUserId, setZoomUserId] = useState("");
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  useEffect(() => {
    if (!user || !zoomUserId) return; // <-- Ensure zoomUserId is set
    const fetchMeetings = async () => {
      try {
        const userMeetingsRef = db.collection("meetings");
        const snapshot = await userMeetingsRef
          .orderBy("end_time", "desc")
          .get();
        const meetingsListWithId = snapshot.docs.map((doc) => {
          const data = doc.data();
          const id = doc.id;
          return { id, ...data };
        });
        console.log("Fetched meetings:", meetingsListWithId); // <-- Debugging line
        // Filter meetings based on zoom_user_id
        const userMeetings = meetingsListWithId.filter(
          (meeting) => meeting.user_id === zoomUserId
        );
        console.log("Filtered meetings:", userMeetings); // <-- Debugging line
        const pendingMeetingsCount = userMeetings.filter(
          (m) => m.status !== "Done"
        ).length;
        setMeetings(userMeetings);
        setPendingMeetings(pendingMeetingsCount);
        setNumConversations(userMeetings.length);
      } catch (error) {
        console.error("Error fetching meetings: ", error);
      }
    };
    fetchMeetings();
  }, [user, zoomUserId]);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (confirmDelete) {
      await db.collection("meetings").doc(id).delete();
      // Refresh local state
      setMeetings((prevMeetings) =>
        prevMeetings.filter((meeting) => meeting.id !== id)
      );
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      console.log("Current user:", currentUser);
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!user) return;
    const fetchName = async () => {
      const userRef = doc(db, "users", user.uid);
      const docSnapshot = await getDoc(userRef);
      if (docSnapshot.exists()) {
        setFirstName(docSnapshot.data().firstName);
        const zoomId = docSnapshot.data().zoom_user_id; // <-- New line
        setZoomUserId(zoomId); // <-- New line
        console.log("Zoom User ID:", zoomId); // <-- Debugging line
      }
    };
    fetchName();
  }, [user]);

  useEffect(() => {
    if (!user || !zoomUserId) return; // Add this line to ensure you have user and zoomUserId
    const fetchNumConversations = async () => {
      const userMeetingsRef = db.collection("meetings");
      const snapshot = await userMeetingsRef
        .where("user_id", "==", zoomUserId)
        .get();
      setNumConversations(snapshot.size);
    };
    fetchNumConversations();
  }, [user, zoomUserId]); // Add zoomUserId as dependency

  return (
    <div>
      <Navbar isVisible={isNavbarVisible} toggleNavbar={toggleNavbar} />
      <Container className="dashboard-container">
        <div className="mobile-navbar-toggle" onClick={toggleNavbar}>
          <DragHandleIcon />
        </div>
        <Typography variant="h1" className="dashboard-heading">
          Hi {firstName}
        </Typography>
        <Typography variant="h2" style={{ fontSize: "24px", color: "#858C94" }}>
          We hope you’re having a great day!
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-start"
          className="dashboard-box"
          my={4}
        >
          <Box
            alignItems="center"
            id="connect-box"
            width={1}
            className="status-box"
            pb={2}
            sx={{
              border: 1,
              borderColor: "grey.500",
            }}
          >
            <div className="icon-container">
              {zoomUserId ? (
                <CheckCircleIcon style={{ color: "green" }} />
              ) : (
                <CancelIcon style={{ color: "red" }} />
              )}
            </div>
            <Box ml={2}>
              <Typography variant="h6" className="connect-text">
                {zoomUserId ? "Connected to Zoom!" : ""}
              </Typography>
              {zoomUserId ? (
                <Typography variant="body1" className="zoom-status">
                  AI Insights Enabled <br />
                  <span style={{ fontSize: "12px" }}>
                    (Ensure you have a Zoom Pro account)
                  </span>
                </Typography>
              ) : (
                <Link to={"/myApps"} variant="body1" className="dashboard-link">
                  Connect to Zoom
                </Link>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: "grey.500", // You can adjust the shade of grey as needed
            }}
            className="count-box"
            alignItems="center"
            width={1 / 3}
          >
            <div
              style={{
                backgroundColor: "#F4F7FE",
                padding: "15px",
                borderRadius: "25px",
              }}
            >
              <FormatListNumberedIcon />
            </div>
            <Box mt={1} ml={2}>
              <Typography
                variant="h6"
                style={{ fontSize: "12px", color: "#000" }}
              >
                Total Conversations
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: "36px",
                  fontWeight: "lighter",
                  color: "#1B2559",
                }}
              >
                {numConversations}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: 1,
              borderColor: "grey.500", // You can adjust the shade of grey as needed
            }}
            alignItems="center"
            className="count-box"
            width={1 / 3}
          >
            <div
              style={{
                backgroundColor: "#F4F7FE",
                padding: "15px",
                borderRadius: "25px",
              }}
            >
              <MarkChatUnreadIcon />
            </div>
            <Box mt={1} ml={2}>
              <Typography
                variant="h6"
                style={{ fontSize: "12px", color: "purple" }}
              >
                Pending Completion
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: "36px",
                  fontWeight: "lighter",
                  color: "#1B2559",
                }}
              >
                {pendingMeetings}
              </Typography>
            </Box>
          </Box>
        </Box>
        {numConversations === 0 ? (
          <div className="no-conversations">
            {zoomUserId ? (
              <>
                <Typography
                  style={{ fontSize: "32px", marginBottom: "10px" }}
                  variant="h2"
                >
                  Step 2
                </Typography>
                <Typography style={{ fontSize: "24px" }} variant="h3">
                  Run a Session on Zoom
                </Typography>
                <Typography
                  style={{ fontSize: "18px" }}
                  variant="body"
                  align="center"
                >
                  Then we can generate a summary and key points of your session
                  automatically with AI
                </Typography>
                <Link to="/calendar">
                  <button
                    style={{
                      padding: "10px",
                      marginTop: "20px",
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "18px",
                    }}
                  >
                    Go to Calendar
                  </button>
                </Link>
              </>
            ) : (
              <>
                <Typography
                  style={{ fontSize: "32px", marginBottom: "10px" }}
                  variant="h2"
                >
                  Step 1
                </Typography>
                <Typography
                  style={{ fontSize: "24px" }}
                  align="center"
                  variant="h3"
                >
                  Connect to Zoom
                </Typography>
                <Typography
                  style={{ fontSize: "18px" }}
                  align="center"
                  variant="body"
                >
                  Then we can start assisting on your Zoom therapy sessions
                </Typography>
                <Link to="/myApps">
                  <button
                    style={{
                      padding: "10px",
                      marginTop: "20px",
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "18px",
                    }}
                  >
                    Connect to Zoom
                  </button>
                </Link>
              </>
            )}
          </div>
        ) : (
          <TableContainer
            id="conversations"
            component={Paper}
            elevation={0}
            className="dashboard-table-container"
          >
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell>Conversation</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {meetings.map((meeting, index) => {
                  return (
                    <TableRow key={index} style={{ backgroundColor: "white" }}>
                      <TableCell>
                        <Link
                          to={`/conversation/${meeting.id}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <Box display="flex" alignItems="center">
                            <Avatar>
                              {meeting.title ? meeting.title[0] : ""}
                            </Avatar>
                            <Box ml={1}>{meeting.title}</Box>
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell>{meeting.patient || "N/A"}</TableCell>{" "}
                      <TableCell>
                        {meeting.status === "Processing Summary" ? (
                          <Box display="flex" alignItems="center">
                            {meeting.status}
                            <CircularProgress
                              size={14}
                              style={{ marginLeft: 8 }}
                            />
                          </Box>
                        ) : meeting.status === "Done" ? (
                          <Box display="flex" alignItems="center" color="green">
                            {meeting.status}
                            <DoneAllIcon style={{ marginLeft: 8 }} />
                          </Box>
                        ) : meeting.status === "Summary Available" ? (
                          <Box display="flex" alignItems="center">
                            {meeting.status}
                            <PendingActionsIcon style={{ marginLeft: 8 }} />
                          </Box>
                        ) : (
                          meeting.status
                        )}
                      </TableCell>
                      <TableCell>
                        {new Date(meeting.end_time).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDelete(meeting.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <Typography
              style={{ fontSize: "12px" }}
              className="summarisation-note"
            >
              Summarisation can take between 2 and 10 minutes depending on
              meeting length, time of day, and other factors.
            </Typography>
          </TableContainer>
        )}
      </Container>

      <Routes>
        <Route
          path="/conversation/:document_id"
          element={<Conversation db={db} />}
        />
      </Routes>
    </div>
  );
}

export default Dashboard;
