import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Navbar from "../components/navBar";
import { Route, Routes, useNavigate } from "react-router-dom"; // Import Link and Route
import { auth } from "../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Conversation from "./conversation";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { doc, getDoc } from "firebase/firestore";
import DownloadIcon from "@mui/icons-material/Download";
import DragHandleIcon from "@mui/icons-material/DragHandle";


const firebaseConfig = {
  apiKey: "AIzaSyB3lhjopNhTn2JcbrCdlDeiayR72VqQb5s",
  authDomain: "therapinsights.firebaseapp.com",
  projectId: "therapinsights",
  storageBucket: "therapinsights.appspot.com",
  messagingSenderId: "236965647773",
  appId: "1:236965647773:web:fbdf0ca3edb007023f4b74",
  measurementId: "G-DWLFNPHQND",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function Patients() {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPatientMeetings, setSelectedPatientMeetings] = useState([]);
  const [zoomUserId, setZoomUserId] = useState("");
  const [meetings, setMeetings] = useState([]);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);


  const navigate = useNavigate();

  // Function to handle navigation
  const navigateToMeeting = (meetingId) => {
    navigate(`/conversation/${meetingId}`);
  };

  useEffect(() => {
    if (!user) return;
    const fetchZoomUserId = async () => {
      const userRef = doc(db, "users", user.uid);
      const docSnapshot = await getDoc(userRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setZoomUserId(data.zoom_user_id);
      }
    };
    fetchZoomUserId();
  }, [user]);

  const downloadSummary = (meeting) => {
    const blob = new Blob([meeting.summary], {
      type: "text/plain;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${meeting.title}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAllSummaries = () => {
    selectedPatientMeetings.forEach((meeting) => {
      downloadSummary(meeting);
    });
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        // Ensure zoomUserId is available
        if (!zoomUserId) return;

        // Fetch meetings where the zoom_user_id matches
        const snapshot = await db
          .collection("meetings")
          .where("user_id", "==", zoomUserId)
          .get();

        const allMeetings = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMeetings(allMeetings);
      } catch (error) {
        console.error("Error fetching meetings: ", error);
      }
    };

    if (zoomUserId) {
      fetchMeetings();
    }
  }, [zoomUserId]);

  const groupByPatient = (meetings) => {
    return meetings.reduce((acc, meeting) => {
      const key = meeting.patient || "Unknown";
      acc[key] = [...(acc[key] || []), meeting];
      return acc;
    }, {});
  };

  const groupedByPatient = groupByPatient(meetings);

  const handleRowClick = (patientMeetings) => {
    setOpen(true);
    setSelectedPatientMeetings(patientMeetings);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      console.log("Current user:", currentUser);
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  return (
    <div>
      <Navbar isVisible={isNavbarVisible} toggleNavbar={toggleNavbar} />
      <Container className="dashboard-container">
        <div className="mobile-navbar-toggle" onClick={toggleNavbar}>
          <DragHandleIcon />
        </div>
        <Typography
          variant="h1"
          style={{ fontSize: "48px", marginTop: "30px", color: "#6D7580" }}
        >
          Patients
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Patient</TableCell>
                <TableCell>Number of Meetings</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedByPatient).map((patient, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(groupedByPatient[patient])}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{patient}</TableCell>
                  <TableCell>{groupedByPatient[patient].length}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Modal */}
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Meetings ({selectedPatientMeetings.length})</DialogTitle>
          <DialogContent>
            <button
              style={{ padding: "10px", borderRadius: "8px" }}
              onClick={downloadAllSummaries}
            >
              Download All Summaries
              <DownloadIcon style={{ marginLeft: "10px" }} />
            </button>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPatientMeetings.map((meeting, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToMeeting(meeting.id)}
                      >
                        {meeting.title}
                      </TableCell>
                      <TableCell>
                        {new Date(meeting.end_time).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <DownloadIcon
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => downloadSummary(meeting)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </Container>

      <Routes>
        <Route
          path="/conversation/:document_id"
          element={<Conversation db={db} />}
        />
      </Routes>
    </div>
  );
}

export default Patients;
