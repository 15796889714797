import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../images/logo.png";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import { auth, signOut } from "../firebase";
import { useNavigate } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import BugReportIcon from "@mui/icons-material/BugReport";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/styles.css";

function Navbar({ isVisible, toggleNavbar }) {
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Signed out successfully");
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  const navigate = useNavigate();

  const handleNavigation = (page) => {
    switch (page) {
      case "Dashboard":
        navigate("/dashboard");
        break;
      case "My Apps":
        navigate("/myApps");
        break;
      case "Report an Issue":
        navigate("/contact");
        break;
      case "Calendar":
        navigate("/calendar");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "patients":
        navigate("/patients");
        break;
      case "Docs":
        window.open("/docs", "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <div className={isVisible ? "navbar" : "navbar navbar-hidden"}>
      <Drawer
        open={isVisible}
        variant="permanent"
        PaperProps={{
          style: {
            width: "305px",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            color: "#727272",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ListItem
            button
            key="Close"
            onClick={toggleNavbar}
            style={{ display: isVisible ? "block" : "none" }} // Direct inline style
          >
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
          </ListItem>

          <img
            src={Logo}
            alt="PMAI Logo"
            style={{ width: "245px", marginBottom: "20px", marginTop: "30px" }}
          />
          <List style={{ width: "100%" }}>
            <ListItem
              button
              key="Dashboard"
              onClick={() => handleNavigation("Dashboard")}
            >
              <ListItemIcon>
                <SpaceDashboardRoundedIcon
                // style={{ color: selectedPage === "Dashboard" ? "#4CAB61" : "#727272" }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Summaries"
              />
            </ListItem>

            <ListItem
              button
              key="My Apps"
              onClick={() => handleNavigation("My Apps")}
            >
              <ListItemIcon>
                <AppsRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Integrations" />
            </ListItem>

            <ListItem
              button
              key="Calendar"
              onClick={() => handleNavigation("Calendar")}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Meetings" />
            </ListItem>
            <ListItem
              button
              key="Patients"
              onClick={() => handleNavigation("patients")}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Patients" />
            </ListItem>
          </List>
        </div>

        <List style={{ width: "100%" }}>
          <ListItem button key="DOcs" onClick={() => handleNavigation("Docs")}>
            <ListItemIcon>
              <HelpCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Docs" />
          </ListItem>
          <ListItem
            button
            key="Report an Issue"
            onClick={() => handleNavigation("Report an Issue")}
          >
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Report an Issue" />
          </ListItem>
          <ListItem
            button
            key="settings"
            onClick={() => handleNavigation("settings")}
          >
            <ListItemIcon>
              <ToggleOnRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem button key="SignOut" onClick={handleSignOut}>
            <ListItemIcon>
              <ExitToAppRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

export default Navbar;
