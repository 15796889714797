import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Logo from "../images/LogoIcon.png";
const ZoomSuccess = () => {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          alt="PMAI Logo"
          style={{ width: "100px", marginBottom: "20px", marginTop: "30px" }}
        />
        <Typography variant="h1" style={{ textAlign: 'center' ,fontSize: "42px", color: "#6D7580" }}>
          Zoom Connection Successful!
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: "18px",
            marginTop: "15px",
            color: "#6D7580",
            marginBottom: "20px",
          }}
        >
          Each recording will be processed and analyzed for insights.
        </Typography>
        <button
          style={{
            padding: "10px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "8px",
            fontSize: "18px",
          }}
          onClick={goToDashboard}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default ZoomSuccess;
