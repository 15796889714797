import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Container,
  TextField,
  Button,
} from "@mui/material";
import Navbar from "../components/navBar";
import { getAuth, updateEmail, updatePassword } from "firebase/auth";
import DragHandleIcon from "@mui/icons-material/DragHandle";


function Settings() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const user = auth.currentUser;
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);


  const handleUpdate = async () => {
    try {
      if (user) {
        if (email) {
          await updateEmail(user, email);
        }
        if (password) {
          await updatePassword(user, password);
        }
        console.log("User info updated.");
      } else {
        console.log("No user is signed in.");
      }
    } catch (error) {
      console.log("Error updating user: ", error);
    }
  };

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  return (
    <div>
      <Navbar isVisible={isNavbarVisible} toggleNavbar={toggleNavbar} />
      <Container className="dashboard-container">
        <div className="mobile-navbar-toggle" onClick={toggleNavbar}>
          <DragHandleIcon />
        </div>
        <Box my={4}>
          <Typography
            variant="h1"
            style={{
              fontSize: "48px",
              marginBottom: "0px",
              marginTop: "30px",
              color: "#6D7580",
            }}
          >
            My Profile
          </Typography>
          <Paper elevation={0}>
            <Box p={3}>
              <TextField
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default Settings;
