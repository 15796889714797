import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyB3lhjopNhTn2JcbrCdlDeiayR72VqQb5s",
  authDomain: "therapinsights.firebaseapp.com",
  projectId: "therapinsights",
  storageBucket: "therapinsights.appspot.com",
  messagingSenderId: "236965647773",
  appId: "1:236965647773:web:fbdf0ca3edb007023f4b74",
  measurementId: "G-DWLFNPHQND",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, getDoc, doc, updateDoc };